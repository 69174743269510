html {
  font-size: 10px;
}

body {
  color: black;
  width: 100%;
  margin: 0;
  font-family: 'Merriweather', serif;
}

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-size: 6rem;
  margin: 0;
}

@media (max-width: 768px) {
  h1 {
      font-size: 4rem;
  }
}
